import React from 'react';
import '../../assets/style/authStyles.css';
import PropTypes from 'prop-types';

export default function AuthLayout({ children }) {
  return <div className="app_background">{children}</div>;
}

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
