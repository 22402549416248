import { Button, Form, Input } from 'antd';
import { LOGIN_RULES as RULES } from 'constants/formValidations';
import Link, { navigate } from 'gatsby-link';
import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';
import { callPostApi } from '../api/axios';
import '../assets/style/authStyles.css';
import AuthLayout from '../components/auth/authLayout';
import MixpanelContext from '../services/tracking';
import { clearFilters } from '../utils/dateFilter';
import { Roles } from '../utils/roles';
import { toast, toastTypes } from '../utils/toast';

export default function Login() {
  const mixpanel = useContext(MixpanelContext);
  const [userEmail, setUserEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  async function handleSignIn() {
    setLoading(true);
    try {
      sessionStorage.setItem('fm-user-email', userEmail);

      // call apis with the entered values
      const { data } = await callPostApi('/v1/api/login', {
        email: userEmail,
        password,
      });

      const cookieExpiryTime = new Date();
      cookieExpiryTime.setTime(cookieExpiryTime.getTime() + 24 * 3600 * 1000);
      // expires in 24 hours
      Cookies.set('Authorization', data.Authorization, {
        expires: cookieExpiryTime,
      });
      const base64Url = data.Authorization.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join(''),
      );
      const {
        roles,
        first_name,
        last_name,
        company_name,
        application_id,
        redirect_password_change = false,
      } = JSON.parse(jsonPayload);

      Cookies.set('User', jsonPayload, {
        expires: cookieExpiryTime,
      });

      mixpanel.set_group('partnerName', [company_name]);
      mixpanel.get_group('partnerName', company_name).set_once({
        partnerName: company_name,
        partnerId: application_id,
      });

      mixpanel.identify(userEmail);
      mixpanel.people.set({
        $email: userEmail,
        $name: `${first_name} ${last_name}`,
        partnerName: company_name,
        partnerId: application_id,
      });

      mixpanel.register({
        $email: userEmail,
        $name: `${first_name} ${last_name}`,
        userType: roles,
        partnerName: company_name,
        partnerId: application_id,
      });

      // smartlook user init
      if (typeof window !== 'undefined' && window?.smartlook) {
        window.smartlook('identify', userEmail, {
          name: `${first_name} ${last_name}`,
          email: userEmail,
          partnerName: company_name,
          partnerId: application_id,
        });
      }

      mixpanel.track('Login', {
        loginMethod: 'email',
      });
      mixpanel.people.increment('Login');

      clearFilters();

      if (redirect_password_change) {
        // Token payload indicates that this user needs to change their password
        // redirect to update password page
        const message =
          'Your password has expired. Please create a new password below ';
        return navigate(
          `/update-password?message=${message}&token=${data.Authorization}`,
        );
      }

      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (params.next) {
        return navigate(params.next);
      }

      if (roles.includes(Roles.SYS_ADMIN)) {
        return navigate('/app/select-fleet');
      }

      return navigate('/app/dashboard');
    } catch (err) {
      const { message } = err?.response?.data || {};
      if (message) toast(toastTypes.ERROR, message);
    } finally {
      setLoading(false);
    }

    return null;
  }

  useEffect(() => {
    mixpanel.track('View Page', {
      pageName: 'Login Page',
    });
  }, [mixpanel]);

  return (
    <AuthLayout>
      <div className="content_height d-flex align-items-center justify-content-center">
        <div className="auth_card">
          <h3 className="header_text">Sign In</h3>
          <Form
            name="login_form"
            initialValues={{
              remember: true,
            }}
            onFinish={() => handleSignIn()}
            autoComplete="off"
          >
            <Form.Item
              style={{ marginBottom: '10px' }}
              name="email"
              rules={RULES.email}
            >
              <Input
                style={{ padding: '14px 15px' }}
                size="large"
                placeholder="Email"
                type="email"
                value={userEmail}
                onChange={(event) => setUserEmail(event.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please enter the password',
                },
              ]}
            >
              <Input.Password
                style={{ padding: '14px 15px' }}
                size="large"
                placeholder="Password"
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </Form.Item>
            <div style={{ margin: '0 auto', width: '150px' }}>
              <Form.Item>
                <Button
                  disabled={
                    loading || userEmail.length === 0 || password.length === 0
                  }
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={loading}
                  style={{
                    width: '100%',
                    fontSize: '18px',
                  }}
                >
                  Log In
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
      <div className="footer_height d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center">
          <a
            href="http://fairmatic.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
            className="mr-2 navigation_link"
          >
            Privacy Policy
          </a>
          <span>|</span>
          <Link to="/forgot-password" className="navigation_link">
            Forgot Password
          </Link>
        </div>
      </div>
    </AuthLayout>
  );
}
